import { useEffect, useState } from 'react';
import styles from './DadosCadastrais.module.css';
import { useUserContext } from '../../UserContext';
import { useAssociadoContext } from '../../useAssociadoContext';
import { useNavigate } from 'react-router-dom';
import { ATUALIZAR_CADASTRO_ASS, GET_CLINICA } from '../../api';
import TituloAssociado from '../Helper/TituloAssociado';
import ClinicaForm from '../AreaGerencial/ClinicaForm';
import Button from '../Forms/Button';
import Modal from 'react-modal';

const DadosCadastrais = () => {
  const { token, setLoading } = useUserContext();
  const { formDataClinica } = useAssociadoContext();
  const [initialValues, setInitialValues] = useState({});
  const [emailSent, setEmailSent] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [clinica, setClinica] = useState<any | undefined>();
  const navigate = useNavigate();

  const fecharModal = () => {
    setEmailSent(false);
    navigate('/area-associado');
  };
  async function atualizaCadastro() {
    try {
      setLoading(true);
      const { url, options } = ATUALIZAR_CADASTRO_ASS(
        token,
        formDataClinica ? formDataClinica : {},
      );
      const response = await fetch(url, options);
      const { message } = await response.json();
      if (!response.ok) throw new Error(message);
      setMensagem(message);
      setEmailSent(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function getClinica() {
      try {
        const { url, options } = GET_CLINICA(token);
        const response = await fetch(url, options);
        const json = await response.json();
        if (!response.ok)
          throw new Error('Erro ao tentar trazer os dados da clínica');
        setClinica(json);
      } catch (error) {
        console.log(error);
      }
    }
    getClinica();
  }, [token]);

  useEffect(() => {
    if (clinica) {
      setInitialValues({
        id_clinica: clinica?.id_clinica,
        nom_clinica: clinica?.nom_clinica,
        num_cnpj: clinica?.num_cnpj,
        des_endereco: clinica?.des_endereco,
        bairro: clinica?.bairro,
        num_cep: clinica?.num_cep,
        data: clinica?.dataFormatada,
        num_inscricao: clinica?.num_inscricao,
        des_email: clinica?.des_email,
        nom_fantasia: clinica?.nom_fantasia,
        telefones: clinica?.telefones,
        especialidades: clinica?.especialidades,
        num_reg_conselho: clinica?.num_reg_conselho,
        cidade: clinica?.cidade,
        uf: clinica?.uf,
        email_clinica: clinica?.email_clinica,
        whatsapp: clinica?.whatsapp,
        insta_clinica: clinica?.insta_clinica,
        rt_clinica: clinica?.rt_clinica,
        cpf_rt: clinica?.cpf_rt,
        num_conselho_rt: clinica?.num_conselho_rt,
        email_rt: clinica?.email_rt,
        telefone_rt: clinica?.telefone_rt,
        nome_fat: clinica?.nome_fat,
        telefone_fat: clinica?.telefone_fat,
        email_fat: clinica?.email_fat,
        resp_cadastro: clinica?.resp_cadastro,
        tel_resp_cadastro: clinica?.tel_resp_cadastro,
        ind_status: clinica?.ind_status,
        cod_clinica: clinica?.cod_clinica,
      });
    }
  }, [clinica]);

  return (
    <section className={`${styles.containerMain} animeLeft`}>
      {clinica && (
        <TituloAssociado
          titulo={
            clinica.nom_fantasia ? clinica.nom_fantasia : clinica.nom_clinica
          }
        />
      )}
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>{mensagem}</p>
        <button onClick={fecharModal}>Fechar</button>
      </Modal>
      <div className={styles.divForm}>
        {initialValues && clinica && (
          <ClinicaForm initialValues={initialValues} />
        )}
        {/* <div className={styles.divDocumentos}>
          <h2>Documentos</h2>
          {docs && docs.length === 0 ? (
            <p>Nenhum documento encontrado para essa clínica</p>
          ) : (
            <div className={styles.divLinks}>
              {docs.map((item: any, index: any) => (
                <Link key={index} to={item.url} target="_blank">
                  {tipos[item.tipo - 1]}
                </Link>
              ))}
            </div>
          )}
        </div> */}
        <div className={styles.botoes}>
          <Button
            descricao="cancelar"
            classe="cancel"
            onClick={() => navigate(-1)}
          />
          <Button
            descricao="salvar"
            classe="submit"
            onClick={atualizaCadastro}
          />
        </div>
      </div>
    </section>
  );
};

export default DadosCadastrais;
